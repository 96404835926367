<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">温馨提示</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt="" width="15" height="25">
        <span class="back">返回</span>
      </div>
    </div>
    <div class="content">
      <div>
        <img :src="fail" alt="" width="100" height="100" class="finish-img">
      </div>
      <div class="finish-text">登录失败，请联系客服处理</div>
      <div class="footer">
        <span>客服电话: 15982083948</span>
      </div>
    </div>
  </div>
</template>
<script>
import cancel from '@/assets/cancel.png'
import fail from '@/assets/fail.png'

export default {
  name: 'PayFail',
  data() {
    return {
      fail,
      cancel,
      time: 3
    }
  },
  mounted() {
    this.countDown()
  },
  methods: {
    countDown() {
      const interVal = setInterval(() => {
        this.time--
        if (this.time <= 0) {
          clearInterval(interVal)
          // this.$router.push({ name: 'Home' })
        }
      }, 1000)
    },
    toBack() {
      this.$ICBCUtil.returnBack()
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  & > .nav {
    width: 100%;
    position: fixed;
    top: 0;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }
  }

  & > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 13rem;

    .finish-img {
      border-radius: 100%;
    }

    & > .finish-text {
      font-size: 1.25rem;
      font-weight: bold;
      margin-top: 2rem;
    }

    & > .footer {
      position: fixed;
      bottom: 10px;
      width: 100vw;
      text-align: center;
      font-size: 0.875rem;
    }

    & > .time {
      margin-top: 1rem;
      display: flex;
      align-items: center;

      & > .time-detail {
        color: #D00000;
        font-size: 1.5rem;
        margin: 0 .2rem;
      }

      .to-his {
        color: #4b95cb;
      }

    }
  }
}

.nav_left_btn_click {
  opacity: 0.5;
}
.nav_right_btn_click {
  opacity: 0.5;
}

</style>
